import React, { useState, useEffect } from 'react';
import {
  ELSTabGroupCKS,
  ELSTabCKS,
  ELSTabPanelCKS,
  ELSPerformanceService,
  ELSPerformanceTopicsTable,
  ELSStorageHelper,
  ELSCommonUIConstants,
  ELSUtilityHelper,
  ELSMededApi
} from '@els/meded-ui-common-react';
import { Button } from '@els/els-react--button';
import { defineMessages } from 'react-intl';
import useIntl from '../../hooks/use-intl';
import NotEnoughData from './NotEnoughData';
import FeatureOn from '../../components/feature-flag/FeatureOn';
import FeatureOff from '../../components/feature-flag/FeatureOff';
import ProgressBar from './ProgressBarLine';
import { orderBy } from 'lodash';
import { Flyout } from '@els/els-react--flyout';
import '../../assets/img/icon-help.svg';

const messages = defineMessages({
  lowestScoringTopics: {
    id: 'AssessmentTopics.text.lowestScoringTopics',
    defaultMessage: 'Lowest scoring topics'
  },
  leastConfidentTopics: {
    id: 'AssessmentTopics.text.leastConfidentTopics',
    defaultMessage: 'Least confident topics'
  },
  emptyPromptLowestScoringTopics: {
    id: 'AssessmentTopics.text.emptyPromptLowestScoringTopics',
    defaultMessage: 'Start taking tests and your lowest scoring topics will show up here.'
  },
  emptyPromptLeastConfidentTopics: {
    id: 'AssessmentTopics.text.emptyPromptLeastConfidentTopics',
    defaultMessage: 'Start taking tests and your least confident topics will show up here.'
  },
  haventTakenAnyPracticeTestsYet: {
    id: 'AssessmentTopics.text.haventTakenAnyPracticeTestsYet',
    defaultMessage: 'Looks like you haven\'t taken any practice tests yet.'
  },
  getStartedCreatingFirstTest: {
    id: 'AssessmentTopics.text.getStartedCreatingFirstTest',
    defaultMessage: 'Let\'s get started by creating your first test.'
  },
  basedOnPracticeQuestions: {
    id: 'AssessmentTopics.text.basedOnPracticeQuestions',
    defaultMessage: 'Based on practice questions you took'
  },
  seeAllButtonLabel: {
    id: 'BookBestBets.labels.seeAllButton',
    defaultMessage: 'See all'
  },
  correctOption: {
    id: 'HomePage.ProgressBar.correct',
    defaultMessage: 'Correct'
  },
  incorrectOption: {
    id: 'HomePage.ProgressBar.Incorrect',
    defaultMessage: 'Incorrect'
  },
  test: {
    id: 'AssessmentTopics.text.test',
    defaultMessage: 'Test'
  },
  description: {
    id: 'AssessmentTopics.text.description',
    defaultMessage: 'Showing up to 3 topics from topics in which you have answered more than 10 questions'
  }
});

const AssessmentTopics = ({assessmentOnly}) => {
  const [topics, setTopics] = useState([]);
  const [filteredItem, setFilteredItem] = useState([]);

  const handleTestButtonClick = (topic) => {
    ELSStorageHelper.driver(ELSCommonUIConstants.security.Locker.DefaultDriver)
      .namespace('')
      .put('TestCreation', topic);

    const productCode = ELSUtilityHelper.getNursingProductCode();
    window.location.href = ELSUtilityHelper.getUrlStudentCreateTestPage(productCode);

    const isUSMLEQuestionBank = ELSUtilityHelper.determineIfUSMLEQuestionBank(topic.isbn);
    ELSUtilityHelper.setUSMLELocalStorage(topic.isbn, isUSMLEQuestionBank);
  };

  useEffect(() => {
    ELSPerformanceService.PerformanceService.getTopics(10)
      .then(res => {
        setTopics(res.data);
      });
  }, []);

  useEffect(() => {
    let filteredTopics = ELSUtilityHelper.filterOutTopicsPerformance(topics).map(topic => ({
      ...ELSUtilityHelper.preferDisplayNameOverText(topic),
      correctPercentage: ELSUtilityHelper.calcPercentageRound(topic.questionsCorrect, topic.questionsAnswered),
      notSurePercentage: ELSUtilityHelper.calcPercentageRound(
        topic.questionsAnswered - topic.questionsConfident,
        topic.questionsAnswered
      )
    }));
    filteredTopics = orderBy(filteredTopics, ['correctPercentage', 'text'], ['desc', 'asc']).slice(0, 3);

    const filteredTopicsIsbn = filteredTopics.map(bank => bank.questionSet.isbn);

    Promise.all(filteredTopicsIsbn.map(isbn => ELSMededApi.get(`/assessment/taxonomyCounts/${isbn}`)))
      .then(results => {
        const topicsResult = results.map(res => res.data.map(topic => topic.children)).flat(Infinity);
        const filteredItem = topicsResult.map(item => {
          const foundTopic = filteredTopics.find(topic => topic.vtwId === item.vtwId);

          if (foundTopic) {
            item.questionSet = foundTopic.questionSet;
            return item;
          }
        }).filter(item => item !== undefined).slice(0, 3);
        setFilteredItem(filteredItem);
      });
  }, [topics.length]);

  const productCode = ELSUtilityHelper.getNursingProductCode();

  const intl = useIntl();
  const lowestScoringTopicsMessage = intl.formatMessage(messages.lowestScoringTopics);
  const leastConfidentTopicsMessage = intl.formatMessage(messages.leastConfidentTopics);
  const correctOptMessage = intl.formatMessage(messages.correctOption);
  const incorrectOptMessage = intl.formatMessage(messages.incorrectOption);
  const testMessage = intl.formatMessage(messages.test);

  const topicTable = topicType => (
    <ELSPerformanceTopicsTable
      topics={topics}
      topicLimit={5}
      topicType={topicType}
      handleTestButtonClick={handleTestButtonClick}
      isHomepageWidget
    />
  );

  const renderHrTag = (lengtOfQuestionsBank, index) => {
    return lengtOfQuestionsBank === index ? null : <div id='filled-state-divider' className='c-els-divider c-els-divider--1o2 u-els-margin-top-1x1o2 u-els-margin-bottom-1x1o2' />;
  };

  return (
    <div className='c-widget-assessment-topics' data-testid='assessment-topics-widget'>
      <>
        {filteredItem.length ? (
          <>
            <FeatureOn featureName='NewStudentHomePage'>
              <div
                className='o-els-flex-layout c-widget-assessment-topics__not-enough-data-v2-container'
                data-testid='assessment-topics-not-enough-data'
              >
                <div className='lower-scoring-topic'>
                  <div className='lower-scoring-topic-header'>
                    <span className='lower-scoring-topic-header-title'>
                      <h3>
                        {intl.formatMessage(messages.lowestScoringTopics)}
                        <Flyout
                          className='lower-scoring-topic-header-flyout'
                          placement='top'
                          trigger='hover'
                          flyout={<span>{intl.formatMessage(messages.description)}</span>}
                          id='help-flyout'
                        >
                          <svg className='lower-scoring-topic-header-icon-help' width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' aria-hidden='true'>
                            <use xlinkHref='#icon-help' />
                          </svg>
                        </Flyout>
                      </h3>
                    </span>
                    <p>{intl.formatMessage(messages.basedOnPracticeQuestions)}</p>
                  </div>
                  <div className='lower-scoring-topic-see-all'>
                    <Button
                      type={Button.Types.LINK}
                      className='assignments-widget__title-button'
                      onClick={() => {
              window.location.href = ELSUtilityHelper.getUrlStudentPerformancePage(productCode);
            }}
                      aria-label='shawAllBtn'
                      title='See all'
                      id='showAllBtnId'
                    >
                      <span>{intl.formatMessage(messages.seeAllButtonLabel)}</span>
                      <svg className='c-ckm-breadcrumb__icon o-els-icon-svg o-els-icon-svg--1x o-els-icon-svg--middle' aria-hidden='true'>
                        <use xlinkHref='#icon-sprite_els-hmds-icon-chevron-right' />
                      </svg>
                    </Button>
                  </div>
                </div>
              </div>
              <div className='c-els-divider c-els-divider--1o2' />
              <div className='c-widget-assessment-topics__filled-state'>
                {filteredItem.map((topic, index) => {
                  return (
                    <div>
                      <div className='topic-block'>
                        <p className='topic-title'>{topic.text}</p>
                        <div className='lower-scoring-topic'> <ProgressBar testMessage={testMessage} correctOptMessage={correctOptMessage} incorrectOptMessage={incorrectOptMessage} handleTestButtonClick={handleTestButtonClick} topic={topic} correctAnswerValue={topic.totalCorrect} incorrectAnswerValue={topic.totalAnswered - topic.totalCorrect} unansweredQuestionsValue={topic.totalQuestions - topic.totalAnswered} totalQuestions={topic.totalQuestions} /></div>
                      </div>
                      {renderHrTag(filteredItem.length - 1, index)}

                    </div>
                  );
                })}
              </div>
            </FeatureOn>
            <FeatureOff featureName='NewStudentHomePage'>
              <ELSTabGroupCKS>
                <ELSTabCKS name={lowestScoringTopicsMessage} text={lowestScoringTopicsMessage} />
                <ELSTabCKS name={leastConfidentTopicsMessage} text={leastConfidentTopicsMessage} />
                <ELSTabPanelCKS name={lowestScoringTopicsMessage}>
                  {topicTable(ELSUtilityHelper.TopicTypes.LOWEST_SCORING_TOPICS)}
                </ELSTabPanelCKS>
                <ELSTabPanelCKS name={leastConfidentTopicsMessage}>
                  {topicTable(ELSUtilityHelper.TopicTypes.LEAST_CONFIDENT_TOPICS)}
                </ELSTabPanelCKS>
              </ELSTabGroupCKS>
            </FeatureOff>
          </>
        ) : (
          <>
            <FeatureOn featureName='NewStudentHomePage'>
              <div>
                <NotEnoughData haventTakenAnyPracticeTestsYet={intl.formatMessage(messages.haventTakenAnyPracticeTestsYet)} getStartedCreatingFirstTest={intl.formatMessage(messages.getStartedCreatingFirstTest)} assessmentOnly={assessmentOnly} />
              </div>
            </FeatureOn>
            <FeatureOff featureName='NewStudentHomePage'>
              <ELSTabGroupCKS>
                <ELSTabCKS name={lowestScoringTopicsMessage} text={lowestScoringTopicsMessage} />
                <ELSTabCKS name={leastConfidentTopicsMessage} text={leastConfidentTopicsMessage} />
                <ELSTabPanelCKS name={lowestScoringTopicsMessage}>
                  <NotEnoughData emptyPrompt={intl.formatMessage(messages.emptyPromptLowestScoringTopics)} assessmentOnly={assessmentOnly} />
                </ELSTabPanelCKS>
                <ELSTabPanelCKS name={leastConfidentTopicsMessage}>
                  <NotEnoughData emptyPrompt={intl.formatMessage(messages.emptyPromptLeastConfidentTopics)} assessmentOnly={assessmentOnly} />
                </ELSTabPanelCKS>
              </ELSTabGroupCKS>
            </FeatureOff>
          </>
        )}
      </>
    </div>
  );
};

export default AssessmentTopics;
