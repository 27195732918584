import React from 'react';
import { defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import { useLocalStorage, useIntl } from '../../hooks';
import { LOCAL_STORAGE } from '../../constants';
import Breadcrumb from './Breadcrumb';

const messages = defineMessages({
  linkHome: {
    id: 'Breadcrumbs.link.home',
    defaultMessage: 'Home'
  },
  linkVideo: {
    id: 'Breadcrumbs.link.video',
    defaultMessage: 'Video'
  }
});

const VideoBreadcrumb = (props) => {
  const intl = useIntl();
  const [localStorageItem, setLocalStorageItem, removeLocalStorageItem] = useLocalStorage(LOCAL_STORAGE.BREADCRUMB);
  const { eid } = props;
  const lastEid = localStorageItem ? localStorageItem.eid : null;

  const createBreadcrumbs = () => {
    if (!localStorageItem) return null;
    if ((lastEid && lastEid !== eid)) removeLocalStorageItem();
    else {
      // update localstorage
      localStorageItem.eid = eid;
      setLocalStorageItem(localStorageItem);

      // create breadcrumb path
      return [
        {name: intl.formatMessage(messages.linkHome), url: '/studyresources'},
        localStorageItem.root,
        {name: intl.formatMessage(messages.linkVideo), url: window.location.pathname}
      ];
    }
  };

  const breadcrumbs = createBreadcrumbs();

  return (
    breadcrumbs ? <Breadcrumb path={breadcrumbs} /> : null
  );
};

VideoBreadcrumb.propTypes = {
  eid: PropTypes.string
};

VideoBreadcrumb.displayName = 'VideoBreadcrumb';
export default VideoBreadcrumb;
