import { replace } from 'ramda';

const normalizeANCType = (type, useUnderscore) => {
  if (!type) return null;
  switch (type.toLowerCase()) {
    case 'audio':
      type = 'audio';
      break;
    case 'case study':
    case 'case studies':
      type = 'case-study';
      break;
    case 'colouring template':
      type = 'coloring-template';
      break;
    case 'further readings':
    case 'further reading':
      type = 'further-reading';
      break;
    case 'glossary':
      type = 'glossary';
      break;
    case 'lesson plan':
    case 'lesson plans':
      type = 'lesson-plan';
      break;
    case 'other information':
      type = 'other-information';
      break;
    case 'other student information':
      type = 'other-student-information';
      break;
    case 'powerpoint':
    case 'presentation':
    case 'presentations':
      type = 'presentation';
      break;
    case 'practice tool':
      type = 'practice-tool';
      break;
    case 'questions and answers':
      type = 'question-answer';
      break;
    case 'reference links':
    case 'references and weblinks':
      type = 'reference-links';
      break;
    case 'teaching tool':
    case 'other teaching tool':
      type = 'teaching-tool';
      break;
    case 'worksheets':
    case 'worksheet':
      type = 'worksheet';
      break;
    case 'video':
    case 'videos':
      type = 'video';
      break;
    case 'image collection':
      type = 'illustrations';
      break;
    default:
      type = null;
  }

  if (useUnderscore && type !== null) {
    type = replace(/-/g, '_', type);
  }

  return type;
};

export {
  normalizeANCType
};
