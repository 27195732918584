import React, { useContext, useEffect, useState } from 'react';
import { defineMessages } from 'react-intl';
import { MainContext } from '../../context/main-context';
import { getUElsPadding } from '../../utils/elementUtils';
import BookshelfInfoDropdown from './BookshelfInfoDropdown';
import InternalLink from '../../components/common/InternalLink';
import { ELSQuestionBankService, ELSUtilityHelper } from '@els/meded-ui-common-react';
import { Icon } from '@els/els-react--icon';
import FeatureOn from '../../components/feature-flag/FeatureOn';

import '../../assets/img/icon-wifi-open-book.svg';
import '../../assets/img/icon-noun-virus.svg';
import '../../assets/img/icon-play-video.svg';
import '../../assets/img/icon-emc.svg';
import '../../assets/img/icon-journal.svg';
import '../../assets/img/icon-create-a-test.svg';
import '../../assets/img/icon-file-materials.svg';
import '../../assets/img/build-an-assessment.svg';
import '../../assets/img/assign-a-mock-exam.svg';

const messages = defineMessages({
  mainHeader: {
    id: 'QuickAction.header',
    defaultMessage: 'Quick actions'
  },
  createATest: {
    id: 'Home.widgets.continueTesting.createATest',
    defaultMessage: 'Create a test'
  },
  BuildAnAssessment: {
    id: 'Home.widgets.assessments.buildAnAssessment',
    defaultMessage: 'Build an assessment'
  },
  BuildACustomizedAssessment: {
    id: 'Home.widgets.assessments.buildACustomizedAssessment',
    defaultMessage: 'Build a customized assessment'
  },
  AssignAMockExam: {
    id: 'Home.widgets.assessments.assignAMockExam',
    defaultMessage: 'Assign a mock exam'
  },
  books: {
    id: 'QuickAction.books',
    defaultMessage: 'Books'
  },
  disease: {
    id: 'QuickAction.disease',
    defaultMessage: 'Disease summaries'
  },
  videos: {
    id: 'QuickAction.videos',
    defaultMessage: 'Videos'
  },
  teachingMaterials: {
    id: 'QuickAction.teachingMaterials',
    defaultMessage: 'Teaching materials'
  },
  emc: {
    id: 'QuickAction.emc',
    defaultMessage: 'EMC'
  },
  journals: {
    id: 'QuickAction.journals',
    defaultMessage: 'Journals'
  },
  launchBookshelf: {
    id: 'StudyTools.links.launchBookshelf',
    defaultMessage: 'Launch Bookshelf'
  },
  readingList: {
    id: 'QuickAction.readingList',
    defaultMessage: 'Create reading list'
  },
  skills: {
    id: 'QuickAction.skills',
    defaultMessage: 'Skills'
  }
});

const QuickActions = (props) => {
  const { user, productConfig, doRedirect, intl } = useContext(MainContext);
  const [hasUSMLEContent, setHasUSMLEContent] = useState(false);
  const showSummaries = user?.hasSummariesAccess(productConfig.context) && props.showSummaries;
  const hasAssessmentAccess = user.hasAssessmentAccess();
  const token = localStorage.getItem('security.elsevier.jwt');

  const setUSMLEMockExams = () => {
    ELSQuestionBankService.getQuestionBanks().then((res) => {
      const isUSMLEMockExams = (banks) => Boolean(banks.filter((bank) => ELSUtilityHelper.determineIfUSMLEMockExams(bank.isbn)).length);
      setHasUSMLEContent(isUSMLEMockExams(res?.data));
    });
  };

  useEffect(() => {
    setUSMLEMockExams();
  }, [hasUSMLEContent]);

  const showBookshelfInfo = (event) => {
    event.preventDefault();
    if (event.target.id === 'q-action-bookshelf') {
      doRedirect('/api/vst/redirect', '_blank');
    }
  };

  return (
    <>
      {!props.isInstructor && !props.isProfessional && hasAssessmentAccess &&
        <div className='quick-actions-v2-container'>
          <div className=''><h2 className='quick-actions-v2-header'>{intl.formatMessage(messages.mainHeader)}</h2></div>
          <div className='c-els-divider c-els-divider--1o2' />
          <div className='quick-actions-v2-wrapper'>
            {hasAssessmentAccess && token && !props.isInstructor && !props.isProfessional &&
              <InternalLink
                className='c-els-button--actions'
                id='q-action-create-a-test'
                href='/test/#/createATest'
              >
                <svg className='quick-actions-svg' width='20' height='20' viewBox='0 0 22 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <use xlinkHref='#icon-create-a-test' />
                </svg>
                {intl.formatMessage(messages.createATest)}
              </InternalLink>}
            <InternalLink
              className='c-els-button--actions'
              id='q-action-bookshelf'
              href='#'
              onClick={showBookshelfInfo}
              data-testid='q-action-bookshelf-btn'
            >
              <Icon
                a11y={{ name: intl.formatMessage(messages.launchBookshelf)}}
                sprite={Icon.Sprites.BOOK}
                id='bookshelf-btn-icon'
                className='bookshelf-icon'
                isDecorative
              />
              {intl.formatMessage(messages.launchBookshelf)}
              <BookshelfInfoDropdown
                className='c-ckm-bookshelf-show-desktop'
                intl={intl}
              />
            </InternalLink>
          </div>
        </div>}

      {(!hasAssessmentAccess || (hasAssessmentAccess && props.isProfessional)) && <div className={getUElsPadding()}>
        <h2 className='quick-action-header'>{intl.formatMessage(messages.mainHeader)}</h2>
        <div className='quick-actions-wrapper'>

          {hasAssessmentAccess && props.isInstructor && token &&
            <InternalLink
              className='c-els-button--actions'
              id='q-build-an-assessment'
              href='/test/#/createAnAssessment'
            >
              <svg className='quick-actions-svg' width='18' height='16' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <use xlinkHref='#build-an-assessment' />
              </svg>
              {intl.formatMessage(messages.BuildACustomizedAssessment)}
            </InternalLink>}
          {hasAssessmentAccess && hasUSMLEContent && props.isInstructor && token &&
            <FeatureOn featureName='mockExams'>
              <InternalLink
                className='c-els-button--actions'
                id='q-assign-a-mock-exam'
                href='/test/#/createAnAssessment?examIsbn=0'
              >
                <svg className='quick-actions-svg' width='18' height='16' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <use xlinkHref='#assign-a-mock-exam' />
                </svg>
                {intl.formatMessage(messages.AssignAMockExam)}
              </InternalLink>
            </FeatureOn>}
          {((props.isInstructor || props.isProfessional) && !(props.isProfessional && props.showCreateReadingList)
) &&
  <InternalLink
    className='c-els-button--actions non-mobile'
    id='q-action-reading-list'
    href='/lists'
  >
    <Icon
      a11y={{ name: intl.formatMessage(messages.readingList)}}
      sprite={Icon.Sprites.LIST}
      id='reading-list-btn-icon'
      className='list-icon'
      isDecorative
    />
    {intl.formatMessage(messages.readingList)}
  </InternalLink>}
          <InternalLink
            className='c-els-button--actions'
            id='q-action-books'
            href='/browse/books'
          >
            <svg className='quick-actions-svg' width='18' height='16' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <use xlinkHref='#icon-wifi-open-book' />
            </svg>
            {intl.formatMessage(messages.books)}
          </InternalLink>
          {showSummaries &&
            <InternalLink
              className='c-els-button--actions'
              id='q-action-summaries'
              href='/browse/summaries'
            >
              <svg className='quick-actions-svg' width='20' height='20' viewBox='0 0 27 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <use xlinkHref='#icon-noun-virus' />
              </svg>
              {intl.formatMessage(messages.disease)}
            </InternalLink>}
          <InternalLink
            className='c-els-button--actions'
            id='q-action-videos'
            href='/browse/videos'
          >
            <svg className='quick-actions-svg' width='20' height='20' viewBox='0 0 22 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <use xlinkHref='#icon-play-video' />
            </svg>
            {intl.formatMessage(messages.videos)}
          </InternalLink>
          {user.hasANCAccess(productConfig.context) &&
            <InternalLink
              className='c-els-button--actions'
              id='q-action-anc'
              href='/browse/anc'
            >
              <svg className='quick-actions-svg' width='20' height='20' viewBox='0 -2 14 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M0 0V18H9.53667L15 12.515V0H0ZM13.3333 11.8267L8.845 16.3333H1.66667V1.66667H13.3333V11.8267V11.8267ZM7 15H8.66667V11.6667H12V10H7V15ZM4 3.66667H11V5.33333H4V3.66667ZM4 6.66667H11V8.33333H4V6.66667Z' fill='#7E4BB0' />
              </svg>
              {intl.formatMessage(messages.teachingMaterials)}
            </InternalLink>}
          {user.hasEMCAccess() &&
            <InternalLink
              className='c-els-button--actions'
              id='q-action-emc'
              data-testid='q-action-emc-btn'
              href='/browse/emc'
            >
              <svg className='quick-actions-svg' width='20' height='20' viewBox='0 0 22 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <use xlinkHref='#icon-emc' />
              </svg>
              {intl.formatMessage(messages.emc)}
            </InternalLink>}
          {
              user.hasJournalAccess(productConfig.context) && <InternalLink
                className='c-els-button--actions'
                id='q-action-journals'
                data-testid='q-action-journals-btn'
                href='/browse/journals'
                                                              >
                <svg className='quick-actions-svg' width='20' height='20' viewBox='0 0 22 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <use xlinkHref='#icon-journal' />
                </svg>
                {intl.formatMessage(messages.journals)}
              </InternalLink>
            }
          <InternalLink
            className='c-els-button--actions'
            id='q-action-bookshelf'
            href='#'
            onClick={showBookshelfInfo}
            data-testid='q-action-bookshelf-btn'
          >
            <Icon
              a11y={{ name: intl.formatMessage(messages.launchBookshelf)}}
              sprite={Icon.Sprites.BOOK}
              id='bookshelf-btn-icon'
              className='bookshelf-icon'
              isDecorative
            />
            {intl.formatMessage(messages.launchBookshelf)}
            <BookshelfInfoDropdown
              className='c-ckm-bookshelf-show-desktop'
              intl={intl}
            />
          </InternalLink>
        </div>
      </div>}
    </>
  );
};

export default QuickActions;
