import React, {Component} from 'react';
import PropTypes from 'prop-types';
import CKApi from '../services/api-helper';
import {compose, propOr, head} from 'ramda';
import {FormattedMessage, defineMessages} from 'react-intl';
import {trackEvent} from '../utils/eventTrackUtils';
import {PAGES} from '../../server/config/dtmConfig';
import InlineLoader from './common/InlineLoader';
import { RadioButtonGroup } from '@els/els-react--radio-button';
import { Button } from '@els/els-react--button';
import { MainContext } from '../context/main-context';
import { Modal } from '@els/els-react--modal';

const messages = defineMessages({
  pathChoiceFormFailed: {
    id: 'PathChoiceFailed.errorr.sectionFailed',
    defaultMessage: 'Selection Failed'
  },
  pathChoiceFormTitle: {
    id: 'PathChoice.aria.title',
    defaultMessage: 'Choose option'
  },
  pathChoiceFormButton: {
    id: 'PathChoice.button.continue',
    defaultMessage: 'Continue'
  }
});
export default class PathChoiceForm extends Component {
  state = {
    rememberChoice: true,
    errorMessage: '',
    isLoading: false
  }

  static contextType = MainContext;

  static propTypes = {
    handlePathChoiceComplete: PropTypes.func.isRequired,
    pathChoices: PropTypes.array.isRequired,
    skipApiRequest: PropTypes.bool,
    showRememberMe: PropTypes.bool
  };

  static defaultProps = {
    skipApiRequest: false,
    showRememberMe: true
  }

  componentWillMount () {
    const getFirstId = compose(propOr('', 'id'), head);
    this.setState({selectedId: getFirstId(this.props.pathChoices)});
  }

  componentDidMount () {
    document.addEventListener('keydown', this.handleEnter);
    const firstOption = this.chooseOrgForm.getElementsByTagName('input')[0];
    if (typeof firstOption !== 'undefined') {
      firstOption.focus();
    }

    trackEvent('newPage', {
      page: {
        name: `${window.__APP_INITIAL_STATE__.dtmPageData.page.language}:${PAGES.PATH_CHOICE.name}`,
        type: PAGES.PATH_CHOICE.type
      }
    });
  }

  componentWillUnmount () {
    document.removeEventListener('keydown', this.handleEnter);
  }

  handleEnter = (evt) => {
    if (evt.key === 'Enter') {
      this.handlePathChoice(evt);
    }
  }

  handlePathChoiceComplete = (response) => {
    trackEvent('newPage', {
      loginSuccess: 'true'
    });
    // Form reset
    this.setState({
      errorMessage: '',
      selectedId: undefined
    });
    // whatever the parent wants to do with it
    this.props.handlePathChoiceComplete(response);
  }

  handlePathChoice = (evt) => {
    evt.preventDefault();
    trackEvent('chooseOrganizationSubmit');
    if (this.props.skipApiRequest) {
      this.props.handlePathChoiceComplete(this.state.selectedId);
      return;
    }
    this.setState({
      errorMessage: '',
      isLoading: true
    });
    CKApi.post('/student/api/auth/path-choice', {
      path_choice_id: this.state.selectedId,
      remember_choice: this.state.rememberChoice
    })
      .then(this.handlePathChoiceComplete)
      .catch(() => this.setState({
        errorMessage: this.context.intl.formatMessage(messages.pathChoiceFormFailed),
        isLoading: false
      }));
  }

  handleSelectionChange = (evt) => {
    this.setState({selectedId: evt.target.value});
  }

  render () {
    const {pathChoices} = this.props;
    const {errorMessage, isLoading} = this.state;
    const options = pathChoices.map((option) => ({label: option.name, value: option.id}));

    return (
      <Modal
        className='c-ckm-path-choice-form-modal'
        ariaTitleText={this.context.intl.formatMessage(messages.pathChoiceFormTitle)}
        shouldCloseOnEscape={false}
        onClose={() => {}}
      >
        <>
          {errorMessage ? <span>{errorMessage}</span> : null}
          <form onSubmit={this.handlePathChoice} id='chooseOrgForm' ref={(form) => { this.chooseOrgForm = form; }}>
            <h2 className='c-ckm-path-choice-form__heading'>
              <FormattedMessage
                id='PathChoiceForm.heading.chooseOption'
                defaultMessage='Choose option'
              />
            </h2>
            {isLoading ? <InlineLoader />
            : <>
              <ul className='c-ckm-path-choice-form__list'>
                <RadioButtonGroup
                  size='small'
                  a11y={{ name: '' }}
                  onChange={this.handleSelectionChange}
                  options={options}
                  className='c-ckm-path-choice-form__button-group'
                />
              </ul>
              <hr />
              <p className='c-ckm-path-choice-form__description'>
                <FormattedMessage
                  id='PathChoiceForm.description'
                  defaultMessage='This option can be changed at any time via the user settings'
                />
              </p>
              <Button
                type={Button.Types.PRIMARY}
                className='c-els-button--small u-els-margin-top-1o2'
                onClick={() => {}}
                htmlType={Button.HtmlTypes.SUBMIT}
                aria-label={this.context.intl.formatMessage(messages.pathChoiceFormButton)}
                title={this.context.intl.formatMessage(messages.pathChoiceFormButton)}
                id='pathChoiceForm_button_continue'
              >
                {this.context.intl.formatMessage(messages.pathChoiceFormButton)}
              </Button>
            </>}
          </form>
        </>
      </Modal>
    );
  }
}

PathChoiceForm.displayName = 'PathChoiceForm';
