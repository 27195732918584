import React from 'react';
import { defineMessages } from 'react-intl';
import { string, oneOf } from 'prop-types';
import { useLocalStorage, useIntl } from '../../hooks';
import { LOCAL_STORAGE } from '../../constants';
import Breadcrumb from './Breadcrumb';

const messages = defineMessages({
  linkHome: {
    id: 'Breadcrumbs.link.home',
    defaultMessage: 'Home'
  },
  linkTOC: {
    id: 'Breadcrumbs.link.toc',
    defaultMessage: 'Table of contents'
  },
  linkBook: {
    id: 'Breadcrumbs.link.content',
    defaultMessage: 'Book chapter'
  },
  linkEmcToc: {
    id: 'Breadcrumbs.link.emctoc',
    defaultMessage: 'Table of contents'
  },
  linkEmcArticles: {
    id: 'Breadcrumbs.link.emcarticles',
    defaultMessage: 'Articles'
  },
  linkEmc: {
    id: 'Breadcrumbs.link.emc',
    defaultMessage: 'EMC'
  },
  linkJournalIssues: {
    id: 'Breadcrumbs.link.journalissues',
    defaultMessage: 'Issues'
  },
  linkJournalArticles: {
    id: 'Breadcrumbs.link.journalarticles',
    defaultMessage: 'Articles'
  },
  linkJournal: {
    id: 'Breadcrumbs.link.journal',
    defaultMessage: 'Full Text Article'
  }
});

const ContentBreadcrumb = (props) => {
  const intl = useIntl();
  const [localStorageItem, setLocalStorageItem, removeLocalStorageItem] = useLocalStorage(LOCAL_STORAGE.BREADCRUMB);
  const { hubeid, issn, crumbType } = props;
  const isEMC = crumbType === 'EMC' || crumbType === 'EMC-TOC' || crumbType === 'EMC-ARTICLES';
  const isJournal = crumbType === 'JOURNAL' || crumbType === 'JOURNAL-ISSUES' || crumbType === 'JOURNAL-ARTICLES';
  const lastHubeid = localStorageItem ? localStorageItem.hubeid : null;
  const lastIssn = localStorageItem ? localStorageItem.issn : null;
  const keyID = isJournal || isEMC ? issn : hubeid;
  const lastKeyID = isJournal || isEMC ? lastIssn : lastHubeid;

  const createBreadcrumbs = () => {
    if (!localStorageItem) return null;
    if ((lastKeyID && lastKeyID !== keyID)) removeLocalStorageItem();
    else {
      // update localstorage
      localStorageItem.hubeid = hubeid;
      localStorageItem.issn = issn;
      setLocalStorageItem(localStorageItem);

      // create breadcrumb path
      const path = [
        {name: intl.formatMessage(messages.linkHome), url: '/studyresources'},
        localStorageItem.root
      ];

      const emcPath = [
        {name: intl.formatMessage(messages.linkEmcToc), url: `/content/emc/toc/${issn}`},
        {name: intl.formatMessage(messages.linkEmcArticles), url: `/content/emc/${issn}/articles-in-prepublication`},
        {name: intl.formatMessage(messages.linkEmc), url: window.location.pathname}
      ];

      const journalPath = [
        {name: intl.formatMessage(messages.linkJournalIssues), url: `/content/journal/issues/${issn}/${hubeid}`},
        {name: intl.formatMessage(messages.linkJournalArticles), url: `/content/journal/articles/${hubeid}`},
        {name: intl.formatMessage(messages.linkJournal), url: window.location.pathname}
      ];

      const bookPath = [
        {name: intl.formatMessage(messages.linkTOC), url: `/content/toc/${hubeid}`},
        {name: intl.formatMessage(messages.linkBook), url: window.location.pathname}
      ];

      const isFoundTOCcheck = path.some(element => {
        if (element?.name === intl.formatMessage(messages.linkTOC)) {
          return true;
        }
      });

      switch (crumbType) {
        case 'EMC-TOC':
          path.push(emcPath[0]);
          break;
        case 'EMC-ARTICLES':
          path.push(emcPath[0]);
          path.push(emcPath[1]);
          break;
        case 'EMC':
          path.push(...emcPath);
          break;
        case 'JOURNAL-ISSUES':
          path.push(journalPath[0]);
          break;
        case 'JOURNAL-ARTICLES':
          path.push(journalPath[0]);
          path.push(journalPath[1]);
          break;
        case 'JOURNAL':
          path.push(...journalPath);
          break;
        case 'TOC':
          path.push(bookPath[0]);
          break;
        case 'BOOK':
          isFoundTOCcheck ? path.push(bookPath[1]) : path.push(...bookPath);
          break;
      }

      return path;
    }
  };

  const breadcrumbs = createBreadcrumbs();

  return (
    breadcrumbs ? <Breadcrumb path={breadcrumbs} /> : null
  );
};

ContentBreadcrumb.propTypes = {
  hubeid: string,
  issn: string,
  crumbType: oneOf(['TOC', 'BOOK', 'EMC-TOC', 'EMC-ARTICLES', 'EMC', 'JOURNAL-ISSUES', 'JOURNAL-ARTICLES', 'JOURNAL', 'wrong-crumb-type']).isRequired
};

ContentBreadcrumb.displayName = 'ContentBreadcrumb';
export default ContentBreadcrumb;
