import React, { useState, useContext } from 'react';
import { defineMessages } from 'react-intl';
import useIntl from '../../hooks/use-intl';
import CKApi from '../../services/api-helper';
import { Icon } from '@els/els-react--icon';
import { Modal } from '@els/els-react--modal';
import ModalButtons from './ModalButtons';
import { Button } from '@els/els-react--button';
import InlineLoader from './InlineLoader';
import { MainContext } from '../../context/main-context';
import { Checkbox } from '@els/els-react--checkbox';

import { DEFAULT_COLLECTION_NAME } from '../../constants';

const existingNameStatus = 409;

const messages = defineMessages({
  title: {
    id: 'saveButton.saveToCollectionButtonLabel',
    defaultMessage: 'Save to collection'
  },
  createCollection: {
    id: 'saveButton.createCollection',
    defaultMessage: 'Create new collection'
  },
  inputLabelSaveCollection: {
    id: 'Presentations.create.inputLabelSaveCollection',
    defaultMessage: 'Enter collection name'
  },
  addToCollectionBody: {
    id: 'saveButton.toast.addToCollectionBody',
    defaultMessage: 'Saved to {forLater} collection.'
  },
  save: {
    id: 'YOS.Modal.Save',
    defaultMessage: 'Save'
  },
  cancel: {
    id: 'Presentations.rename.cancel',
    defaultMessage: 'Cancel'
  },
  serverError: {
    id: 'Presentations.rename.serverError',
    defaultMessage: 'There was an error renaming your presentation. Please try again.'
  },
  existingNameError: {
    id: 'Presentations.rename.existingNameError',
    defaultMessage: ' already exists. Please provide a unique name.'
  },
  validationError: {
    id: 'Presentations.rename.noName',
    defaultMessage: 'Please provide a name.'
  },
  forLater: {
    id: 'saveButton.toast.forLater',
    defaultMessage: 'For later'
  }
});

const sharedSuccessToastProperties = {
  isVisible: true,
  autoHide: true,
  icon: '#icon-sprite_els-hmds-icon-checkmark',
  iconColor: 'positive',
  color: 'positive',
  hideAnchor: true
};

const MediaPopoutSaveToCollection = (props) => {
  const context = useContext(MainContext);

  const intl = useIntl();
  const [isShowInput, setIsShowInput] = useState(false);
  const [newCollectionName, setNewCollectionName] = useState();
  const [existingNameError, setExistingNameError] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const loadingItems = props.loadingItems || [];

  const getIsLoading = (collectionId) => {
    return loadingItems.includes(collectionId.toString());
  };

  const showInput = () => {
    setIsShowInput(true);
  };

  const handleChangeButton = (event) => {
    const check = event.target.checked;
    const collectionId = event.target.name;

    if (!check) {
      props.handleDeleteButton(collectionId, props.originCollections[collectionId], false, true);
    } else {
      props.handleSaveButton(collectionId, props.sectionId, props.sectionTitle, false, false);
    }
  };

  const handleCreateCollection = (event) => {
    setNewCollectionName(event.target.value);
    if (event.target.value === '') {
      setExistingNameError(false);
      setValidationError(false);
    }
  };

  const handleSaveToCollection = (evt) => {
    evt.preventDefault();

    if (!newCollectionName) {
      setValidationError(true);
      return;
    }
    // eslint-disable-next-line no-useless-escape
    const sanitizedName = newCollectionName.replace(/[^\p{L}\p{N}&().,\/ ]/gu, ''); // Sanitize the name, remove all special characters except & ( ) . , /

    CKApi.post('/student/api/collections', {
      name: sanitizedName
    }).then((response) => {
      setExistingNameError(false);
      setValidationError(false);

      CKApi.put(`/student/api/collections/${response.data.id}`, {
        eid: props.eid,
        sectionId: props.section
      })
        .then((response) => {
          props.handleClose();
          props.updateDefaultCollectionId(response.data.id);
          props.updateCollections();
          const newCollectionURL = `${context.productConfig.path}/collections?collectionId=${response.data.id}`;
          context.addToast({
            body: `<div class='c-ckm-study-tools__toast-collection-body'>
            <div class='c-ckm-study-tools__toast-collection-text'>
                        ${intl.formatMessage(messages.addToCollectionBody,
    {
      forLater: `<a class='c-ckm-study-tools__toast-collection-link' href=${newCollectionURL}>
                        ${newCollectionName}
                        </a>`
    })}
              </div>
                      </div>`,
            ...sharedSuccessToastProperties
          });
        });
    }).catch(({ response }) => {
      setExistingNameError(response.status === existingNameStatus);
    });
  };

  const isEditable = (user, collection) => {
    return collection.userId === parseInt(user.user_id);
  };

  const closeInputField = () => {
    setIsShowInput(false);
    setNewCollectionName('');
    setExistingNameError(false);
    setValidationError(false);
  };

  return (
    <Modal
      onClose={props.handleClose}
      shouldCloseOnEscape
      shouldPreventClickOverlayToClose
      ariaTitleText={intl.formatMessage(messages.title)}
      focusTrapProps={{ active: false }}
    >
      <div className='c-ckm-collections-save-modal__container'>
        <h1 className='c-ckm-collections-save-modal__title'>
          {intl.formatMessage(messages.title)}
        </h1>
        {props.collections
          ? props.collections.map((collection, index) => {
            if (isEditable(context.user, collection)) {
              return (
                <Checkbox
                  label={collection.name === DEFAULT_COLLECTION_NAME ? intl.formatMessage(messages.forLater) : collection.name}
                  name={collection.id}
                  key={index}
                  disabled={getIsLoading(collection.id)}
                  className='c-ckm-collections-save-modal__checkbox'
                  checked={props.originCollections && Object.keys(props.originCollections).includes(`${collection.id}`)}
                  onChange={handleChangeButton}
                />
              );
            }
          })
          : <InlineLoader />}
        <div className='c-ckm-collections-save-modal__border' />
        {!isShowInput && <Button
          onClick={showInput}
          type={Button.Types.LINK}
          id='create-new-btn'
          className='c-ckm-collections-save-modal__button'
                         >
          <Icon
            a11y={{ name: 'name' }}
            sprite={Icon.Sprites.PLUS}
            textAlignment='bottom'
            size={Icon.Sizes.S}
            isVisible
            id='create-new-btn-icon'
          />
          <div className='c-ckm-collections-save-modal__button-text u-els-margin-left-1o2'>{intl.formatMessage(messages.createCollection)}</div>
        </Button>}
        {isShowInput &&
          <form
            onSubmit={handleSaveToCollection}
            className='c-ckm-collections-save-modal__form'
          >
            <label
              className='u-ckm-visuallyhidden'
              htmlFor='createCollectionInput'
            >
              {intl.formatMessage(messages.inputLabelSaveCollection)}
            </label>
            <input
              aria-required='true'
              type='text'
              className='c-ckm-field__input'
              placeholder={intl.formatMessage(messages.inputLabelSaveCollection)}
              id='createCollectionInput'
              onChange={handleCreateCollection}
            />
            {existingNameError && <div className='u-els-color-warn-on-dark u-els-margin-bottom'><strong>{newCollectionName}</strong> {intl.formatMessage(messages.existingNameError)}</div>}
            {validationError && <div className='u-els-color-warn-on-dark u-els-margin-bottom'>{intl.formatMessage(messages.validationError)}</div>}
            <ModalButtons
              secondaryButton={{ label: intl.formatMessage(messages.cancel), className: 'c-ckm-collections-save-modal__form-button', onClick: closeInputField }}
              primaryButton={{ label: intl.formatMessage(messages.save), className: 'c-ckm-collections-save-modal__form-button', onClick: handleSaveToCollection }}
            />
          </form>}
      </div>
    </Modal>
  );
};

export default MediaPopoutSaveToCollection;
